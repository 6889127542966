@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body{background-color:#263237;color:#FFFFFF;}

@media only screen and (max-width: 600px) {
  html{font-size:12px;}
}

a{text-decoration:none;color:#FFF;}
button:disabled {background-color: #cccccc;color: #888888;cursor: not-allowed;pointer-events: none;}
.active-login{position:static;height: 4px;left:0px;right:0px;bottom:0px;text-decoration:underline;background:#09CAEC;margin-top:10px;}
.add-card{background-color:#18D5F5;border-radius:0.5rem;width:100%;padding-bottom:51.5%;position:relative;
  cursor:pointer;border:0.5rem solid #FFF;box-shadow: 2px 2px 5px rgba(0,0,0,0.25);}
.add-card:hover{background-color:#18B5D8;}
.add-card-holder{position:absolute;top:0;bottom:0;left:0;right:0;}
.add-card-text{color:#fff;font-size:1.4rem;text-align:center;}
.add-card-icon-holder{text-align:center;margin-bottom:1rem;}
.add-card-icon{width:5rem;}

.agency-card{background-image:url('./img/card-bg-light.png');background-repeat:no-repeat;
  background-position:center;background-size:cover;
  border-radius:1.5rem;width:100%;padding-bottom:51.5%;position:relative;
  box-shadow:4px 2px 16px rgba(39,50,55,0.68);cursor:default;}
  .agency-card:not(.no-hover){cursor:pointer;}
.agency-card-hover{position:absolute;top:0;bottom:0;left:0;right:0;}
.agency-card:not(.no-hover):hover .agency-card-hover{opacity:1;border-radius:1.5rem;background-color:rgba(255,255,255,0.4);
  box-shadow:0px 0px 16px rgba(255,255,255,0.25);}
.agency-card:not(.no-hover).dark:hover .agency-card-hover{opacity:1;border-radius:1.5rem;background-color:rgba(39,50,55,0.2);
  box-shadow:0px 0px 16px rgba(39,50,55,0.05);}
.agency-card.dark{background-image:url('./img/card-bg-dark.png');}
.agency-card.dark:hover{background-color:#263237;}
.agency-card-holder{position:absolute;top:0;bottom:0;left:0;right:0;}
.agency-card-text{color:#fff;font-size:1.4rem;text-align:center;}
.agency-card-icon-holder{position:absolute;top:2.5%;bottom:2.5%;left:10%;right:10%;
  background-repeat:no-repeat;background-position:center center;background-size:contain;}
.agency-card-icon{max-width:100%;max-height:100%;}
.agency-card-icon{width:60%;}
.agency-card-name{font-size:1.2rem;position:absolute;bottom:0.5rem;left:0.5rem;right:0.5rem;color:#263237;}
.agency-card.dark .agency-card-name{color:#FFFFFF;}
.agency-card-position{position:relative;}
.agency-card.dark .card-close{color:#FFFFFF;}
.agency-card:hover .card-close{opacity:1;}
.agency-code{margin-top: 2.1rem;}
.agency-connect{margin-left: 2.5rem}
.agency-row{min-height:6rem;padding:1rem;background-color:#263237;margin:0 -2.7rem;border-bottom:1px solid #37474F;}
.agency-row-name{font-size:1.1rem;font-weight:500;color:#FFF;text-decoration:none;}
.agency-row-name:hover{color:#EDEFF1;text-decoration:none;}
.agency-row-agents{margin-top:0.5rem;}
.agency-row-agents a{text-decoration:none;}

.agency-row .agency-card{border-radius:0.5rem;}
.agent-logo-dl{margin-right:1.7rem;}

.alert-bar{z-index:10000;bottom:1rem;right:1rem;left:1rem;}
.mobile .alert-bar{left:1rem;}
.alert-bar .close{position:relative;top:-3px;}
.alert-danger{background-color:#C2195B;border-color:#AD1556;color:#FFF;}
.agent-title{margin-left: 6rem;}
.app-title-bar{text-align:center;font-size:1.2rem;padding:1rem 0;margin-top:1rem;position:relative;}
.app-headline-bar{text-align:center;font-size:1.2rem;padding:1rem 0;position:relative;}

.btn{cursor:pointer;}
.btn-light{background-color:#253236;}
.btn-search{border-radius:4px;display:inline-block;font-weight:600;color:#fff;text-align:center;vertical-align:middle;
	-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;
	padding:.4rem .1rem;font-size:.7rem;line-height:1.3;border-radius:.25rem;cursor:pointer;
	transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	text-decoration:none;}
.btn-dark{background-color:#37474F;border:1px solid #263237;}
.btn-dark:hover,.btn-primary:active,.btn-primary:focus{background-color:#263237;border:1px solid #263237;}

.btn-outline-subtle{border:1px solid #D1D5DB;background-color:transparent;}
.btn-outline-subtle:hover{border-color:#3AD5F0;background-color:transparent;}
.btn-outline-subtle:active{border-color:#3AD5F0;background-color:#CEF4FB;}
.btn-sm{padding:2px 16px;}
.btn-primary{background-color:#18D5F5;border:1px solid #18D5F5;}
.btn-primary:hover,.btn-primary:active,.btn-primary:focus{background-color:#18B5D8!important;border:1px solid #18B5D8;}
.btn-primary:active,.btn-primary:focus{box-shadow:0 0 0.25rem #18B5D8!important;}

.btn-outline-primary{border-color:#18D5F5;color:#18D5F5;font-weight:700;}
.btn-outline-primary:hover{border-color:#18D5F5;background-color:#18D5F5;color:#FFF;}

.card-close{position:absolute;top:1rem;right:1rem;color:#273237;font-size:1rem;opacity:0.1;}

.center{left:50%;top:50%;transform:translate(-50%,-50%);position:relative;}

.close-right{position:absolute;top:12px;right:20px;}
.copy-btn{padding: 0 .9rem;}
.cover{position:fixed;top:0;bottom:0;left:0;right:0;z-index:10;}
.cover-bg{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(39,50,55,0.6)}
.cursor-pointer{cursor:pointer; position:relative;}

.detail-row{position:relative;padding:1rem 1rem;background-color:#37474F;margin:0;border-bottom:1px solid #263237;}
.detail-row-empty{color:#455963;}

.detail-row-left{width:33%;float:left;}
div.text-light:hover{color:#cbd3da!important}
.dl-logo{padding-top:.7rem}
.drop-down a,.drop-down label {display: flex;flex-wrap: wrap;justify-content: space-between;height: 3.5rem;padding: 0.85rem;color: #fff;transition: all 0.25s ease-in;font-size: 1.2rem;}
.drop-down a:focus,.drop-down a:hover,.drop-down label:focus,.drop-down label:hover {color: #18B5D8;}
.drop-down a:focus,.drop-down a:hover>span:nth-child(2) {color: rgba(255, 255, 255, 1);}
.drop-down label {cursor: pointer;}
.drop__list input[type=checkbox]:checked+label+ul {max-height: 1000px;}
.drop__list input[type=checkbox]:checked+label>span {transform: rotate(90deg);}

.external-frame{width:100%;height:600px;}
.elite-link{border-color:#18D5F5;color:#18D5F5;font-weight:700;}

.form-actions{padding:0.5rem 1rem;background-color:#37474F;border-bottom:1px solid #263237;overflow:hidden;}
.form-actions .btn{padding-top:0.5rem;padding-bottom:0.5rem;font-weight:700;}

.form-control,.form-control:focus{background-color:transparent;color:#FFFFFF;overflow-wrap: break-word}
.form-control:focus {border-color:#18D5F5;box-shadow:0 0 0 0.2rem rgba(24,213,245,0.25);}
.form-control::placeholder{color:#788F9C;}
.form-control::-ms-input-placeholder{color:#788F9C;}
.form-control:disabled, .form-control[readonly] {background-color:transparent; opacity:1; border-color:#546D79;}

.form-hint{color:#D0D8DC;padding-top:0.5rem;font-size:0.8rem;}
.form-row{position:relative;padding:0.5rem 1rem;background-color:#37474F;margin:0;border-bottom:1px solid #263237;}
.form-row label{float:left;width:33%;padding:0.5rem 0;margin:0;font-size:1rem;line-height:1.5;
  font-size:0.8rem;color:#8FA3AE;}
.form-row .form-control{border:none;width:66%;float:left;font-size:1.1rem;height:inherit;}

.gb-image{background-image: url('./img/image.png'); background-position: center center; background-repeat: no-repeat; background-size: contain; width:50px;height:50px;background-color:black;}
.group-list a,.group-list label {padding-left: 2rem;}
.group-list {height: 100%;max-height: 0;overflow: hidden;transition: max-height 0.5s ease-in-out;}

.home-title-bar{text-align:left;color:#8FA3AE;font-size:1.2rem;padding:1rem 0 0.5rem 0;margin-top:1rem;text-transform:uppercase;}
.home-title-icon-holder{float:right;position:relative;top:-0.25rem;opacity:0.5;cursor:pointer;}
.home-title-icon-holder:hover{opacity:1;}
.home-title-icon{width:1.8rem;}
.hq-agent{margin-top: .8rem; margin-right: 2.5rem}

.input-toggle{border: 1px solid #09CAEC;border-radius: 60px;padding: 1px;background-color: #09CAEC;height: 15px;width: 27px;cursor: pointer;padding-right:13px;}
.input-toggle:hover{background-color: #3AD5F0;border-color:#3AD5F0;}
.input-toggle.input-toggle-off{background-color: #EF4444;border-color: #EF4444;}
.input-toggle.input-toggle-off:hover{background-color: #F87171;border-color:#F87171;}
.input-toggle-knob{width: 10px;height: 10px; border-radius: 8px;background-color: #FFFFFF;}
.input-toggle.input-toggle-off{padding-left: 13px;}
.hover-highlight{cursor:pointer;}
.hover-highlight:hover{color:#18D5F5;}

label>span>img {width: 10px;}
label>span {float: right;transition: transform 0.65s ease;}

.list{background-color:#37474F;padding:0;margin-bottom:0;}
.list .item{list-style-type:none;border-bottom:1px solid #263237;line-height:2rem;padding:0.5rem 1rem;display:block;position:relative;}
.list .item.interactive{cursor:pointer;}
.list .item.interactive:hover{background-color:#FFFFFF;color:#263237;}
.list a.item{text-decoration:none;color:#FFFFFF;}
.list .item.secondary{color:#788F9C;font-size:0.9rem;padding:0.25rem 1rem;}
.list .item.secondary:after{width:0.7rem;height:0.7rem;}
.list .item.interactive:hover:after{opacity:1;}

.modal{background-color:rgba(39,50,55,0.75);color:#263237;}

.nav-link{color:#EDEFF1;text-align:center;padding: 1rem 0;}
.nav-link.active{color:#18D5F5;}
.nav-link:hover{color:#FFF;}
.nav-link.active:hover{color:#18B5D8;}

.nav-link .nav-icon-active{display:none;width:1.5rem;}
.nav-link .nav-icon{display:inline-block;width:1.5rem;}
.nav-link.active .nav-icon-active{display:inline-block;}
.nav-link.active .nav-icon{display:none;}

.nav-text{display:block;font-size:0.8rem;padding-top:0.25rem;}

.navbar-placeholder{height:5rem;}
.navbar-position{position:fixed;top:0;left:0;right:0;z-index:100;background-color:rgba(38,50,55,0.95);border-bottom:1px solid #37474F;}

.onboard-actions{text-align:center;}

.onboard-copy{text-align:center;font-size:1.4rem;}

.onboard-dots{text-align:center;}
.onboard-dots button{width:0.5rem;height:0.5rem;border-radius:1rem;background-color:#37474F;display:inline-block;margin:0 0.25rem;padding:0;}
.onboard-dots button.carousel__dot--selected{background-color:#EDEFF1;}

.onboard-next{color:#FFF;position:absolute;right:2rem;top:12rem;width:2rem;height:2rem;background-color:rgba(55,71,79,0.7);border-radius:2rem;text-align:center;line-height:1.5rem;}
.onboard-next:disabled{display:none;}
.onboard-prev{color:#FFF;position:absolute;left:2rem;top:12rem;width:2rem;height:2rem;background-color:rgba(55,71,79,0.7);border-radius:2rem;text-align:center;line-height:1.5rem;}
.onboard-prev:disabled{display:none;}

.onboard-panel{text-align:center;}

.agency-hq{padding-top: .7rem;}

.page-link{position: relative;display: block;padding: 0.5rem 0.75rem;margin-left: -1px;line-height: 1.25;color: #FFFFFF;background-color: #263237;border: 1px solid #dee2e6;}
.phone-login{width: 163px;height: 40px;left: 196px;top: 308px;}
.phone-text{font-style: normal;font-weight: bold;font-size: 14px;line-height: 24px;letter-spacing: 0.17px;
	color: #FFFFFF;text-align: center;margin-top:10px;}
.pre-scrollable{max-height:80px;}
.purge-input{width:50%; margin:0 auto;}

.red{color:#EF4444;}
.red:hover{cursor: pointer;}
.save-bar{position:fixed;bottom:0;left:0;right:0;background-color:rgba(39,50,55,0.7);padding:1rem;z-index:2;}


.search-results{padding: 7px 11px;}
.search-results-container h4{color:#6B7280;font-size: 1.2rem;font-weight: 400;}
.search-results-title{display: flex;align-items: baseline;}
.search-results-info{margin-left: auto;}
.search-result-bottom{display:flex;flex-wrap:wrap;align-items:center;flex-direction:row;margin:6px 0 0 -12px;overflow:hidden;}
.search-result-sub{font-size:0.9rem;padding:8px 0 8px 12px;display:flex;align-items: center;flex-direction:row;}
.search-result-stats{margin-left: auto;display:flex;align-items:center;flex-direction:row;}
.search-result-policy-icon{width: 16px;height: 16px;margin-right: 4px;}

.search-result-policyholder{padding: 12px 15px 8px 11px;text-decoration:none;width:100%;height:100%;display:block;}
.search-result-policyholder:last-child{border-bottom:1px solid #FFFFFF}
a.search-result-policyholder:hover{color: #00AACF;text-decoration:none !important;}

.search-result-top{display: flex;flex-direction: row;align-items: center;flex-wrap:wrap;}
.search-result-verified svg{width: 20px;height: 20px;}
.search-result-name{font-size: 1rem;font-weight: 600;}
.search-result-action{margin-left: auto;}

.section-close{cursor:pointer;opacity:0.5;float:right;top:1rem;}
.section-close:hover{opacity:1;}
.section-title{padding:0.5rem 1rem;background-color:transparent;color:#FFF;}
.square-btn{width: 50px;height: 50px;}
.hq-search-btn{width:50px;}

.text-primary{color:#18D5F5 !important;}
.text-primary:hover,.text-primary:active{color:#18B5D8 !important;}

.toggle-agent{margin-top: 1.9rem;}
.tooltip-div{position: absolute;padding: 9px 12px;color: black;
	font-size: 0.9rem;line-height: 1;font-weight: 500;border-radius: 3px;top: -20%;left: 0;
	text-transform: none;}
  .tooltip-clear-rollout{color: black;padding: 6px 12px;font-size: 0.7rem;line-height: 1;font-weight: 500;border-radius: 3px;}
.tooltip-clear-rollout span::after{content:""; margin-left: -5px;border-width: 5px;border-style: solid;border-color: white transparent transparent transparent; position: absolute;top: 90%;left: 10%;}
.tooltip-clear{color: black;padding: 6px 12px;font-size: 0.7rem;line-height: 1;font-weight: 500;border-radius: 3px;}
.tooltip-clear span::after{content:""; margin-left: -5px;border-width: 5px;border-style: solid;border-color: white transparent transparent transparent; position: absolute;top: 90%;left: 10%;}
.tooltip-pos{background-color:white;height:25px;width:100px;position: absolute;left:0%;right: 60%;top: -130%;color:white;border-radius:10px;}
.tooltip-pos-rollout{background-color:white;height:25px;width:100px;position: absolute;left:0%;right: 60%;top: -170%;color:white;border-radius:10px;}
.tooltip-div span::after {content: "";position: absolute;top: 85%;left: 10%;margin-left: -5px;border-width: 5px;border-style: solid;border-color: white transparent transparent transparent;}

.user-row{border-bottom:1px solid #37474F;line-height:38px;}
.user-row-id{color:#8FA3AE;}
.width-60{width:120px;}
ul {padding: 0;}
ul,li {margin: 0;}
li {list-style-type: none;}